























































import Vue from "vue";
import { mapActions } from "vuex";
import { DateTime } from "luxon";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import path from "path";

import { delay } from "@/utils";
import {
  getMemberApplication,
  submitBankTransferReceiptForMemberApplication,
} from "@/utils/backend";
import MemberApplicationFormRegion from "@/components/member/ApplicationFormRegion.vue";
import { storage } from "@/plugins/firebase";

const EXPIRY_DAY = 30;

export default Vue.extend({
  components: {
    MemberApplicationFormRegion,
  },
  props: {
    uuid: {
      type: String,
    },
  },
  name: "UploadBankTransferReceipt",
  computed: {
    file: {
      get(): File | undefined {
        return this.internalFile;
      },
      set(v: File | undefined): void {
        // get file size
        if (!v) return;
        const size = v.size;
        const LIMIT = 50 * 1024 * 1024;
        if (size <= LIMIT) {
          this.internalFile = v;
        } else {
          this.displayMessage(
            "Your profile image is too large. Please choose one smaller than or equal to 50MB."
          );
        }
      },
    },
    profileImageUrl(): string | null {
      if (!this.file) return this.remoteImagePath;

      return URL.createObjectURL(this.file);
    },
  },
  data: () => {
    return {
      internalFile: undefined as File | undefined,
      remoteImagePath: "",
      applicationLoaded: false,
      isApplicationValid: false,
      applicationInvalidReason: "",
      applicationAmount: 0,
      applicationExpiryDate: new Date(),
    };
  },
  methods: {
    ...mapActions(["showLoading", "hideLoading", "displayMessage"]),
    async save() {
      this.showLoading();
      await delay(1000);
      this.displayMessage(
        "Your Subscription configuration was modified successfully"
      );
      this.hideLoading();
    },
    onFileButtonClick(): void {
      let fileUpload = document.getElementById("fileUploader");
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    dragFile(e): void {
      if (
        e &&
        e.dataTransfer &&
        e.dataTransfer.files &&
        e.dataTransfer.files.length
      ) {
        const file: File = e.dataTransfer.files[0];

        const type: string = file.type.toLowerCase();
        if (
          type.includes("png") ||
          type.includes("jpg") ||
          type.includes("jpeg") ||
          type.includes("gif")
        ) {
          this.file = file;
        } else {
          this.displayMessage("The file type is not supported.");
        }
      }
    },
    async fetch() {
      this.showLoading();
      try {
        const result = await getMemberApplication(this.uuid);

        if (
          result.status === "STATUS_WAITING_PAYMENT" &&
          !result.upload_receipt_url
        ) {
          this.isApplicationValid = true;
          this.applicationAmount = result.amount;
          this.applicationExpiryDate = DateTime.fromJSDate(
            new Date(result.created_at)
          )
            .plus({ days: EXPIRY_DAY })
            .toJSDate();
        } else {
          if (result.status === "STATUS_PAYMENT_RECEIVED") {
            this.applicationInvalidReason =
              "The Bank Transfer Receipt image was well received. You membership application will be proceed once the payment is confirmed.";
          } else if (result.status === "STATUS_EXPIRED") {
            this.applicationInvalidReason =
              "The payment time for this application is expired, please re-submit your application.";
          }
        }

        await delay(1000);
        this.hideLoading();
        this.applicationLoaded = true;
      } catch (err) {
        this.hideLoading();
        this.displayMessage(
          "The link to upload bank transfer receipt is invalid."
        );
        this.$router.replace({ path: "/" });
        console.error(err);
      }
    },
    removeFile() {
      this.internalFile = undefined;
    },
    async confirm() {
      if (!this.file) {
        this.displayMessage(
          "Please select an image of the bank transfer receipt to upload."
        );
        return;
      }

      try {
        this.showLoading();
        const fileRef = ref(
          storage,
          `applications/bank_transfer_receipts/${
            this.uuid
          }/${DateTime.now().toMillis()}${path.extname(this.file.name)}`
        );
        await uploadBytes(fileRef, this.file);
        const imagePath = await getDownloadURL(fileRef);

        await submitBankTransferReceiptForMemberApplication(
          this.uuid,
          imagePath
        );

        this.hideLoading();
        this.displayMessage(
          "Your image of bank transfer receipt was uploaded successfully. "
        );
        this.$router.replace({ path: "/" });
      } catch (err) {
        console.error(err);
        this.hideLoading();
      }
    },
  },
  mounted() {
    this.fetch();
  },
});
